<template>
  <v-card elevation="3" min-height="500" class="rounded-10">
    <v-card-title>
      <v-btn
        icon
        fab
        depressed
        @click.stop="goBack"
        class="px-5 text-none ml-auto"
        color="primary"
      >
        <v-icon large>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <router-view :key="$route.fullPath" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  methods: {
    goBack() {
      if (this.$route.query.hasOwnProperty("redirect_uri"))
        this.$router.push(this.$route.query.redirect_uri);
      else this.$router.push({ name: "connect" });
    },
  },
};
</script>

<style lang="scss" scoped>
 
</style>